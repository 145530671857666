import React, { useContext } from "react"
import ThemeContext from "../utils/theme"
import Particles from "react-particles-js";

// class TsPart extends Component {
//   render() {
    export default () => {
        const { dark, toggleDark, toString } = useContext(ThemeContext)
    return (
      <Particles
        id="tsparticles" 
        className="mytsp"
        width="100%"
        height="180px" 
        options={{
          background: {
            color: {
              value: "#2867b2",//"#0d47a1",
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: "canvas",
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#3f51b5",
            },
            links: {
              color: "#FFA500",//"#000000",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 6,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
        style={{
            "backgroundColor": `${dark ? "#8a0900" : "#2867b2"}` ,//"#2867b2",//"#0d47a1",//"#252527",
            "position": "relative",
            "width": "100%",
            "height": "100%"
          }}
      />
    );
  //}
}